<button mat-menu-item (click)="expandLoopCollapse = !expandLoopCollapse" class="!ap-px-5">
    <div apTrackHover #hoverTracker="hoverTrackerDirective"
        class="ap-flex ap-flex-grow ap-min-h-[27px] ap-items-center">

        <app-generic-step-mention-item [stepIndex]="stepIndex"
            [stepMention]="stepMention"></app-generic-step-mention-item>
        <div class="ap-flex-grow"></div>
        <ap-button *ngIf="hoverTracker.isHovered" btnColor="primary" btnStyle="basic" type="button" btnSize="small"
            [buttonIsInsideAutocomplete]="true" [darkLoadingSpinner]="true" (click)="$event.stopPropagation();"
            (buttonClicked)="mentionClicked.emit(stepMention)">Insert
        </ap-button>
        <mat-icon [svgIcon]="expandLoopCollapse ? 'custom_expand_less' : 'custom_expand_more'"
            class="mat-icon-rtl-mirror !ap-mr-[5px]">

        </mat-icon>
    </div>
</button>


<ng-container *ngIf="expandLoopCollapse">
    <ng-container *ngIf="mentionItemsToShow$ | async as result">
        <div #indexMentionListItem="hoverTrackerDirective" apTrackHover
            *ngIf="result.markedNodesToShow[childrenNodes[0].propertyPath]" (click)="emitIndexMention()"
            (keyup.enter)="emitIndexMention()"
            class="mat-tree-node ap-min-w-[0] ap-min-h-[27px] ap-relative  ap-flex ap-items-center ap-typography-body-2"
            [style.padding-left]="FIRST_LEVEL_PADDING_IN_MENTIONS_LIST + 'px'" mat-menu-item>
            Index <ap-button *ngIf="indexMentionListItem.isHovered" btnColor="primary" btnStyle="basic" type="button"
                btnSize="small" class="ap-absolute ap-right-[10px]" [buttonIsInsideAutocomplete]="true">Insert
            </ap-button>
        </div>
        <div #ItemMentionListItem="hoverTrackerDirective"
            *ngIf="result.markedNodesToShow[childrenNodes[1].propertyPath]" apTrackHover (click)="openPathDialog()"
            (keyup.enter)="openPathDialog()"
            class="mat-tree-node ap-min-w-[0] ap-min-h-[27px] ap-relative  ap-flex ap-items-center ap-typography-body-2"
            [style.padding-left]="FIRST_LEVEL_PADDING_IN_MENTIONS_LIST + 'px'" mat-menu-item>
            Item <ap-button *ngIf="ItemMentionListItem.isHovered" btnColor="primary" btnStyle="basic" type="button"
                btnSize="small" class="ap-absolute ap-right-[10px]" [buttonIsInsideAutocomplete]="true">Custom Path
            </ap-button>
        </div>
    </ng-container>

</ng-container>

<ng-container *ngIf="customPathDialogClosed$ | async">

</ng-container>
<ng-container *ngIf="search$ | async"></ng-container>