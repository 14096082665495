<ng-container *ngIf="thirdPartyProvidersToShow$ | async as show">
    <div class="text-center ap-flex ap-flex-col ap-gap-2">
        <!-- TODO: figure out if we are going to streamline the process of adding third party authentication ui or not -->

        <ap-button type="button" *ngIf="show[ThirdPartyAuthnProvider.GOOGLE]"
            (buttonClicked)="signInWithThirdPartyProvider(ThirdPartyAuthnProvider.GOOGLE)" btnColor="basic"
            btnStyle="stroked" [fullWidthOfContainer]="true">
            <div class="ap-flex ap-gap-4 ap-items-center ap-justify-center">
                <svg-icon [svgStyle]="{ width: '15px', height: '15px' }" src="assets/img/custom/auth/google-icon.svg">
                </svg-icon>
                {{isForSignup? signUpText:signInText }} Google
            </div>
        </ap-button>

        <ap-button type="button" *ngIf="show[ThirdPartyAuthnProvider.GITHUB]"
            (buttonClicked)="signInWithThirdPartyProvider(ThirdPartyAuthnProvider.GITHUB)" btnColor="basic"
            btnStyle="stroked" [fullWidthOfContainer]="true">
            <div class="ap-flex ap-gap-2 ap-items-center ap-justify-center">
                <svg-icon [svgStyle]="{ width: '15px', height: '15px' }" src="assets/img/custom/auth/github.svg">
                </svg-icon>
                {{isForSignup? signUpText:signInText }} Github
            </div>
        </ap-button>
    </div>

    <div class="ap-mt-2 ap-mb-2 ap-flex ap-gap-3 ap-items-center"
        *ngIf="show[ThirdPartyAuthnProvider.GOOGLE] || show[ThirdPartyAuthnProvider.GITHUB]">
        <div class="ap-flex-grow ap-border-t ap-border-solid ap-border-dividers"></div>
        <div class="ap-typography-body-2  !ap-font-semibold" i18n>
            OR
        </div>
        <div class="ap-flex-grow ap-border-t ap-border-solid ap-border-dividers"></div>
    </div>

</ng-container>

<ng-container *ngIf="signInWithThirdPartyProvider$ | async"></ng-container>