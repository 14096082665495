export const cobalt2 = {
  base: 'vs-dark',
  inherit: true,
  rules: [
    {
      background: '193549',
      token: '',
    },
    {
      foreground: 'e1efff',
      token:
        'punctuation - (punctuation.definition.string || punctuation.definition.comment)',
    },
    {
      foreground: 'ff628c',
      token: 'constant',
    },
    {
      foreground: 'ffc600',
      token: 'entity',
    },
    {
      foreground: 'ff9d00',
      token: 'keyword',
    },
    {
      foreground: 'ffc600',
      token: 'storage',
    },
    {
      foreground: '3ad900',
      token: 'string -string.unquoted.old-plist -string.unquoted.heredoc',
    },
    {
      foreground: '3ad900',
      token: 'string.unquoted.heredoc string',
    },
    {
      foreground: '0088ff',
      token: 'comment',
    },
    {
      foreground: '80ffbb',
      token: 'support',
    },
    {
      foreground: 'cccccc',
      background: '0d3a58',
      token: 'variable',
    },
    {
      foreground: 'ff80e1',
      token: 'variable.language',
    },
    {
      foreground: 'ffee80',
      token: 'meta.function-call',
    },
    {
      foreground: 'f8f8f8',
      background: '800f00',
      token: 'invalid',
    },
    {
      foreground: '80fcff',
      token: 'entity.other.inherited-class',
    },
    {
      foreground: '9eff80',
      token: 'string.quoted source',
    },
    {
      foreground: '9eff80',
      token: 'string.quoted',
    },
    {
      foreground: '80ff82',
      token: 'string constant',
    },
    {
      foreground: '80ffc2',
      token: 'string.regexp',
    },
    {
      foreground: 'edef7d',
      token: 'string variable',
    },
    {
      foreground: 'ffb054',
      token: 'support.function',
    },
    {
      foreground: 'eb939a',
      token: 'support.constant',
    },
    {
      foreground: 'ff1e00',
      token: 'support.type.exception',
    },
    {
      foreground: '8996a8',
      token: 'meta.preprocessor.c',
    },
    {
      foreground: 'afc4db',
      token: 'meta.preprocessor.c keyword',
    },
    {
      foreground: '73817d',
      token: 'meta.sgml.html meta.doctype',
    },
    {
      foreground: '73817d',
      token: 'meta.sgml.html meta.doctype entity',
    },
    {
      foreground: '73817d',
      token: 'meta.sgml.html meta.doctype string',
    },
    {
      foreground: '73817d',
      token: 'meta.xml-processing',
    },
    {
      foreground: '73817d',
      token: 'meta.xml-processing entity',
    },
    {
      foreground: '73817d',
      token: 'meta.xml-processing string',
    },
    {
      foreground: '9effff',
      token: 'meta.tag',
    },
    {
      foreground: '9effff',
      token: 'meta.tag entity',
    },
    {
      foreground: '9effff',
      token: 'meta.selector.css entity.name.tag',
    },
    {
      foreground: 'ffb454',
      token: 'meta.selector.css entity.other.attribute-name.id',
    },
    {
      foreground: '5fe461',
      token: 'meta.selector.css entity.other.attribute-name.class',
    },
    {
      foreground: '9df39f',
      token: 'support.type.property-name.css',
    },
    {
      foreground: 'f6f080',
      token: 'meta.property-group support.constant.property-value.css',
    },
    {
      foreground: 'f6f080',
      token: 'meta.property-value support.constant.property-value.css',
    },
    {
      foreground: 'f6aa11',
      token: 'meta.preprocessor.at-rule keyword.control.at-rule',
    },
    {
      foreground: 'edf080',
      token: 'meta.property-value support.constant.named-color.css',
    },
    {
      foreground: 'edf080',
      token: 'meta.property-value constant',
    },
    {
      foreground: 'eb939a',
      token: 'meta.constructor.argument.css',
    },
    {
      foreground: 'f8f8f8',
      background: '000e1a',
      token: 'meta.diff',
    },
    {
      foreground: 'f8f8f8',
      background: '000e1a',
      token: 'meta.diff.header',
    },
    {
      foreground: 'f8f8f8',
      background: 'ee3a43',
      token: 'markup.deleted',
    },
    {
      foreground: 'f8f8f8',
      background: '806f00',
      token: 'markup.changed',
    },
    {
      foreground: 'f8f8f8',
      background: '154f00',
      token: 'markup.inserted',
    },
    {
      background: '8fddf630',
      token: 'markup.raw',
    },
    {
      background: '004480',
      token: 'markup.quote',
    },
    {
      background: '1d425d',
      token: 'markup.list',
    },
    {
      foreground: 'c1afff',
      fontStyle: 'bold',
      token: 'markup.bold',
    },
    {
      foreground: 'c8e4fd',
      background: '001221',
      fontStyle: 'bold',
      token: 'markup.heading',
    },
    {
      foreground: 'ffffff',
      background: 'ffffaa',
      token: 'sublimelinter.annotations',
    },
    {
      foreground: 'da2000',
      token: 'sublimelinter.mark.error',
    },
    {
      foreground: 'ffffff',
      background: 'ff4a52',
      token: 'sublimelinter.outline.illegal',
    },
    {
      background: 'ff0000',
      token: 'sublimelinter.underline.illegal',
    },
    {
      foreground: 'ffffff',
      token: 'sublimelinter.gutter-mark',
    },
    {
      foreground: 'edba00',
      token: 'sublimelinter.mark.warning',
    },
    {
      foreground: 'ffffff',
      background: 'df9400',
      token: 'sublimelinter.outline.warning',
    },
    {
      background: 'ff0000',
      token: 'sublimelinter.underline.warning',
    },
    {
      foreground: 'ffffff',
      background: 'ffffff',
      token: 'sublimelinter.outline.violation',
    },
    {
      background: 'ff0000',
      token: 'sublimelinter.underline.violation',
    },
    {
      foreground: '80ffc2',
      token: '',
    },
    {
      foreground: '80ffc2',
      token: 'entity.name.class.php',
    },
    {
      foreground: '80ffc2',
      token: '',
    },
    {
      foreground: '80ffc2',
      token: 'entity.name.type.class.php',
    },
    {
      foreground: '80ffc2',
      token: '',
    },
    {
      background: '333333',
      token: 'entity.name.function.php',
    },
    {
      foreground: 'f92672',
      token: 'markup.deleted.git_gutter',
    },
    {
      foreground: 'a6e22e',
      token: 'markup.inserted.git_gutter',
    },
    {
      foreground: '967efb',
      token: 'markup.changed.git_gutter',
    },
    {
      foreground: '565656',
      token: 'markup.ignored.git_gutter',
    },
    {
      foreground: '565656',
      token: 'markup.untracked.git_gutter',
    },
    {
      foreground: '1f4662',
      token: 'brackethighlighter.tag',
    },
    {
      foreground: 'ffc600',
      token: 'brackethighlighter.curly',
    },
    {
      foreground: 'ffc600',
      token: 'brackethighlighter.round',
    },
    {
      foreground: 'ffc600',
      token: 'brackethighlighter.square',
    },
    {
      foreground: 'ffdd00',
      token: 'brackethighlighter.angle',
    },
    {
      foreground: 'ffc600',
      token: 'brackethighlighter.quote',
    },
    {
      foreground: 'f92672',
      token: 'brackethighlighter.unmatched',
    },
    {
      foreground: 'ffa5f3',
      background: '1d3c52',
      token: 'storage.type.function.js',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '2eff00',
      token: 'punctuation.definition.string.begin.js',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '2eff00',
      token: 'punctuation.definition.string.end.js',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '2affdf',
      token: '',
    },
    {
      foreground: '2affdf',
      token: 'string.unquoted.label.js',
    },
    {
      foreground: '2affdf',
      token: '',
    },
    {
      foreground: '2affdf',
      token: 'meta.object-literal.key.js',
    },
    {
      foreground: '2affdf',
      token: '',
    },
    {
      foreground: '80ffbb',
      token: 'meta.object-literal.key.string.quoted',
    },
    {
      foreground: 'ffc600',
      token: 'meta.object-literal.key.entity',
    },
    {
      foreground: 'ffffff',
      token: 'meta.object-literal.key.punctuation',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '2eff00',
      token: 'punctuation.definition.string.template.begin.js',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '2eff00',
      token: 'punctuation.definition.string.template.end.js',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '9eff80',
      token: 'string.template.js',
    },
    {
      foreground: 'ffffff',
      token: '',
    },
    {
      foreground: 'ffffff',
      token: 'string.template.js punctuation',
    },
    {
      foreground: 'ffffff',
      token: '',
    },
    {
      foreground: 'ffc600',
      token: 'template.entity',
    },
    {
      foreground: 'ff80e1',
      token: 'string.template variable',
    },
    {
      foreground: 'ffa5f3',
      background: '1d3c52',
      token: 'storage.type.function.jsx',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '2eff00',
      token: 'punctuation.definition.string.begin.jsx',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '2eff00',
      token: 'punctuation.definition.string.end.jsx',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '2affdf',
      token: '',
    },
    {
      foreground: '2affdf',
      token: 'string.unquoted.label.jsx',
    },
    {
      foreground: '2affdf',
      token: '',
    },
    {
      foreground: '2affdf',
      token: 'meta.object-literal.key.jsx',
    },
    {
      foreground: '2affdf',
      token: '',
    },
    {
      foreground: '80ffbb',
      token: 'meta.object-literal.key.string.quoted',
    },
    {
      foreground: 'ffc600',
      token: 'meta.object-literal.key.entity',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '2eff00',
      token: 'punctuation.definition.string.template.begin.jsx',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '2eff00',
      token: 'punctuation.definition.string.template.end.jsx',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '9eff80',
      token: 'string.template.jsx',
    },
    {
      foreground: 'ffffff',
      token: '',
    },
    {
      foreground: 'ffffff',
      token: 'string.template.jsx punctuation',
    },
    {
      foreground: 'ffffff',
      token: '',
    },
    {
      foreground: 'ffa5f3',
      background: '1d3c52',
      token: 'storage.type.function.ts',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '2eff00',
      token: 'punctuation.definition.string.begin.ts',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '2eff00',
      token: 'punctuation.definition.string.end.ts',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '2eff00',
      token: 'punctuation.definition.string.template.begin.ts',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '2eff00',
      token: 'punctuation.definition.string.template.end.ts',
    },
    {
      foreground: '2eff00',
      token: '',
    },
    {
      foreground: '9eff80',
      token: 'string.template.ts',
    },
    {
      foreground: 'ffffff',
      token: '',
    },
    {
      foreground: 'ffffff',
      token: 'string.template.ts punctuation',
    },
    {
      foreground: 'ffffff',
      token: '',
    },
    {
      foreground: '2affdf',
      token: '',
    },
    {
      foreground: '2affdf',
      token: 'string.unquoted.label.ts',
    },
    {
      foreground: '2affdf',
      token: '',
    },
    {
      foreground: '2affdf',
      token: 'meta.object-literal.key.ts',
    },
    {
      foreground: '2affdf',
      token: '',
    },
    {
      foreground: 'fb94ff',
      token: '',
    },
    {
      foreground: 'fb94ff',
      token: 'entity.name.class.js',
    },
    {
      foreground: 'fb94ff',
      token: '',
    },
    {
      foreground: 'fb94ff',
      token: 'entity.name.type.class.js',
    },
    {
      foreground: 'fb94ff',
      token: '',
    },
    {
      foreground: 'fb94ff',
      token: 'entity.name.class.jsx',
    },
    {
      foreground: 'fb94ff',
      token: '',
    },
    {
      foreground: 'fb94ff',
      token: 'entity.name.type.class.jsx',
    },
    {
      foreground: 'fb94ff',
      token: '',
    },
    {
      foreground: 'ff9d00',
      token: '',
    },
    {
      foreground: 'ff9d00',
      token: 'storage.type.class.js',
    },
    {
      foreground: 'ff9d00',
      token: '',
    },
    {
      foreground: 'ff9d00',
      token: 'storage.type.class.jsx',
    },
    {
      foreground: 'ff9d00',
      token: '',
    },
    {
      foreground: 'ffc600',
      token: 'punctuation.separator.key-value.css',
    },
    {
      foreground: '0088ff',
      background: '17344a',
      token: 'invalid.illegal.bad-comments-or-CDATA.html',
    },
    {
      foreground: 'ffc600',
      token: '',
    },
    {
      foreground: 'ffc600',
      token: 'punctuation.definition.list_item.markdown',
    },
    {
      foreground: 'ffc600',
      token: '',
    },
    {
      foreground: 'ffc600',
      token: 'punctuation.definition.blockquote.markdown',
    },
    {
      foreground: 'ffc600',
      token: '',
    },
    {
      foreground: 'ffc600',
      token: 'punctuation.definition.string.begin.markdown',
    },
    {
      foreground: 'ffc600',
      token: '',
    },
    {
      foreground: 'ffc600',
      token: 'punctuation.definition.string.end.markdown',
    },
    {
      foreground: 'ffc600',
      token: '',
    },
    {
      foreground: 'ffc600',
      token: 'markup.underline.link.image.markdown',
    },
    {
      foreground: 'ffc600',
      token: '',
    },
    {
      foreground: 'ffc600',
      token: 'entity.other.attribute-name.html',
    },
    {
      foreground: 'ffc600',
      token: 'entity.other.attribute-name.event.html',
    },
    {
      foreground: 'ffc600',
      token: '',
    },
    {
      foreground: 'ffc600',
      token: 'entity.other.attribute-name.class.html',
    },
    {
      foreground: 'ffc600',
      token: '',
    },
    {
      foreground: 'ffc600',
      token: 'entity.other.attribute-name.style.html',
    },
    {
      foreground: 'ffc600',
      token: 'entity.other.attribute-name.id.html',
    },
    {
      foreground: 'ffc600',
      token: 'entity.other.attribute-name.tag.jade',
    },
    {
      foreground: 'ffc600',
      token: 'constant.other.symbol.ruby',
    },
    {
      foreground: 'ffc600',
      token: 'entity.other.attribute-name.jsx',
    },
  ],
  colors: {
    'editor.foreground': '#FFFFFF',
    'editor.background': '#193549',
    'editor.selectionBackground': '#0050a4',
    'editor.lineHighlightBackground': '#1f4662',
    'editorCursor.foreground': '#ffc600',
    'editorWhitespace.foreground': '#7f7f7fb2',
    'editorIndentGuide.background': '#3b5364',
    'editorIndentGuide.activeBackground': '#ffc600',
  },
};
