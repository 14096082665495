<div class="ap-w-full ap-h-full ap-px-[30px] ap-pt-[50px]">
  <div class="ap-h-full">
    <div class="ap-my-3 ap-w-[720px]">
      <ap-warning-box>
        <div class="ap-flex ap-items-center ap-gap-2 ap-ml-[-3px] ">
          <svg-icon [svgStyle]="{ width: '20px', height: '20px' }" src="assets/img/custom/warn.svg">
          </svg-icon> <span class="!ap-font-semibold">Deprecation Note</span>
        </div>
        <div>
          Thank you for using our chatbots, we are deprecating this feature and will be removing it from
          Activepieces
          on the 15th of December.
        </div>
        <div>
          For full announcement please visit <a class="!ap-typography-body-1"
            href="https://community.activepieces.com/t/important-update-sunsetting-chatbots/1326"
            rel="noreferrer noopener" target="_blank">this thread</a>.
        </div>
      </ap-warning-box>
    </div>


    <ap-page-title [title]="title" [isBeta]="true"></ap-page-title>

    <div class="ap-min-h-[70%]">
      <table mat-table [dataSource]="dataSource" class="ap-w-[100%]" aria-label="Chatbots">
        <ng-container matColumnDef="displayName">
          <th mat-header-cell *matHeaderCellDef i18n>Name</th>
          <td mat-cell *matCellDef="let chatbot">{{ chatbot.displayName }}</td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="!ap-text-center">
            <div class="ap-flex ap-justify-end">
              <ap-button btnColor="primary" btnSize="medium" (buttonClicked)="createChatbot()" i18n>
                + New Chatbot
              </ap-button>
            </div>
          </th>
          <td mat-cell *matCellDef="let chatbot" class="!ap-text-center">
            <div class="ap-flex ap-gap-2 ap-items-center ap-justify-end">
              <ng-container *ngIf="chatbot.visibilityStatus === VisiblityStatus.PRIVATE  && chatbot.connectionId">
                <ng-container *ngTemplateOutlet="shareableLink;context:{$implicit:chatbot}"></ng-container>
              </ng-container>
              <ng-container *ngIf="chatbot.visibilityStatus === VisiblityStatus.PUBLIC || !chatbot.connectionId">
                <ap-icon-button
                  (click)="$event.stopPropagation(); chatbot.connectionId? copyChatbotUrl(chatbot):openSettings(chatbot.id)"
                  [tooltipText]="shareChatbotLinkTooltipText(chatbot)" iconFilename="share.svg" color="primary"
                  [width]="20" [height]="20" [buttonDisabled]="!chatbot.connectionId" [height]="16"></ap-icon-button>
              </ng-container>

              <ap-icon-button
                (click)="$event.stopPropagation();chatbot.connectionId? openChatbot(chatbot.id):openSettings(chatbot.id)"
                [tooltipText]="chatWithBotTooltipText(chatbot)" iconFilename="chat-bubble.svg" color="primary"
                [width]="20" [height]="20" [buttonDisabled]="!chatbot.connectionId" [height]="16"></ap-icon-button>
              <ap-icon-button (click)="deleteChatbot(chatbot); $event.stopPropagation()" tooltipText="Delete Bot"
                i18n-tooltipText iconFilename="trash.svg" color="warn" [width]="20" [height]="20"
                [height]="16"></ap-icon-button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr class="ap-cursor-pointer hover:!ap-bg-hover" (click)="openSettings(row.id)" mat-row
          [class.ap-hidden]="dataSource.isLoading$ | async" *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <ng-container *ngIf="(dataSource.isLoading$ | async) === true">
        <div class="ap-flex ap-items-center ap-justify-center ap-flex-grow ap-h-[500px]">
          <ap-loading-icon height="50px" width="51px" [outlineLoader]="true" [whiteLoader]="false">
          </ap-loading-icon>
        </div>
      </ng-container>
      <ng-container *ngIf="
          dataSource.data.length === 0 &&
          (dataSource.isLoading$ | async) === false
        ">
        <div class="ap-flex ap-items-center ap-justify-center ap-h-full ap-h-[500px] ap-py-8" i18n>
          Humans are still in command, create your first bot to start the
          revolution
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-container *ngIf="createBot$ | async"></ng-container>
<ng-container *ngIf="updateBot$ | async"> </ng-container>
<ng-container *ngIf="copyChatbotUrl$ | async"> </ng-container>


<ng-template #shareableLink let-chatbot>

  <ap-icon-button (click)="$event.stopPropagation()" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
    iconFilename="share.svg" color="primary" [width]="20" [height]="20" [height]="16"></ap-icon-button>

  <mat-menu #menu="matMenu" xPosition="before">
    <div (click)="$event.stopPropagation()" class="ap-flex ap-flex-col ap-gap-4 ap-max-w-[250px] md:ap-max-w-[325px] ">
      <div class="ap-typography-body-2 ap-px-4 " i18n>
        Your bot is private, enable public access so you can share its link.
      </div>
      <div class="ap-flex ap-px-4 ">
        <ap-button btnSize="medium" placement="bottom" btnStyle="stroked" [darkLoadingSpinner]="true"
          (buttonClicked)="enablePublicSharingForBot(chatbot,menuTrigger);" [loading]="updatingBot" i18n>
          Enable Public Access
        </ap-button>
      </div>

    </div>
  </mat-menu>

</ng-template>