<ng-container *ngIf="!showVerificationNote; else verificationTemplate">
	<div class="ap-max-w-[425px]">
		<div class="ap-typography-headline-5 ap-text-center ap-mb-4" i18n>Forgot Password</div>
		<div class="ap-typography-body-1 ap-mb-2" i18n>
			If the user exists we'll send you an email with a link to reset your password.
		</div>
		<form (ngSubmit)="sendPasswordReset()" class="ap-mb-2">
			<mat-form-field class="ap-w-full" appearance="outline">
				<mat-label>Email</mat-label>
				<input matInput placeholder="john@doe.com" [formControl]="emailFormControl" />
				<mat-error *ngIf="emailFormControl.invalid" i18n>
					<ng-container *ngIf="emailFormControl.hasError('required')">
						Email is required
					</ng-container>
					<ng-container *ngIf="emailFormControl.hasError('email')">
						Email is invalid
					</ng-container>
				</mat-error>

			</mat-form-field>
		</form>

		<div class="text-center ap-mt-2 ap-flex ap-flex-col ap-gap-2">
			<ap-button [loading]="loading" (buttonClicked)="sendPasswordReset()" btnColor="primary"
				[fullWidthOfContainer]="true" i18n>
				Send email
			</ap-button>
		</div>
	</div>
	<div class="ap-text-center ap-typography-caption ap-mt-2">
		<a (click)="goBackToSignIn()" class="ap-cursor-pointer" i18n>Back to sign in</a>
	</div>
</ng-container>

<ng-template #verificationTemplate>
	<app-send-email-for-auth-action [email]="emailFormControl.value"
		[otpType]="OtpType.PASSWORD_RESET"></app-send-email-for-auth-action>
</ng-template>
<ng-container *ngIf="sendPasswordReset$ | async"></ng-container>