<button mat-menu-item apTrackHover #hoverTracker="hoverTrackerDirective" class="!ap-px-5"
  (click)="expandSample? expandSample=false : expandSample=true">
  <div class="ap-flex ap-items-center">
    <ng-container *ngIf="sampleData$ | async as result; else noSampleDataNote">

      <app-generic-step-mention-item [stepIndex]="stepIndex" [stepMention]="_stepMention"
        [indentation]="(!result.children || result.children.length===0) && !result.value"></app-generic-step-mention-item>
      <div class="ap-flex-grow"></div>
      <ap-button *ngIf="hoverTracker.isHovered " btnColor="primary" btnStyle="basic" type="button" btnSize="small"
        [buttonIsInsideAutocomplete]="true" (click)="mentionClicked.emit(_stepMention)" i18n>Insert
      </ap-button>
      <mat-icon class="mat-icon-rtl-mirror !ap-mr-[5px]"
        [svgIcon]="expandSample ? 'custom_expand_less' : 'custom_expand_more'"
        *ngIf="(result.children && result.children.length>0) || result.value">

      </mat-icon>
    </ng-container>
    <ng-template #noSampleDataNote>
      <app-generic-step-mention-item [stepIndex]="stepIndex"
        [stepMention]="_stepMention"></app-generic-step-mention-item>
      <div class="ap-flex-grow"></div>
      <mat-icon [svgIcon]="expandSample ? 'custom_expand_less' : 'custom_expand_more'"
        class="mat-icon-rtl-mirror !ap-mr-[5px]">

      </mat-icon>
    </ng-template>

  </div>


</button>
<ng-container *ngIf="expandSample">
  <ng-container *ngIf="sampleData$ | async as result; else testStepTemplate">

    <app-step-mentions-tree *ngIf="result.children" [stepOutputObjectChildNodes]="result.children"
      [markedNodesToShow]="result.markedNodesToShow" [stepDisplayName]="_stepMention.step.displayName"
      (mentionClicked)="mentionClicked.emit($event)"></app-step-mentions-tree>

    <ng-container *ngIf="result.error">
      <div class="ap-pl-6 ap-py-2">
        {{result.error}}
      </div>
    </ng-container>

    <ng-container *ngIf="result.value !==undefined && expandSample">
      <button apTrackHover #resultNodeHoverTracker="hoverTrackerDirective" class="!ap-pl-0" mat-menu-item
        (click)="mentionClicked.emit(_stepMention)">
        <span class=" ap-flex-grow ap-transition-all"
          [style.padding-left]="FIRST_LEVEL_PADDING_IN_MENTIONS_LIST+ 'px'" i18n>result:
          <span class="ap-transition-all ap-text-primary" [matTooltip]="'' + result.value || ''">{{result.value}}
          </span></span>
        <ap-button *ngIf="resultNodeHoverTracker.isHovered" btnColor="primary" btnStyle="basic" type="button"
          btnSize="small" class="ap-absolute ap-right-[10px]" [buttonIsInsideAutocomplete]="true"
          [darkLoadingSpinner]="true" (click)="$event.stopPropagation();"
          (buttonClicked)="mentionClicked.emit(_stepMention)" i18n>Insert
        </ap-button>
      </button>
    </ng-container>

  </ng-container>
  <ng-template #testStepTemplate>
    <div class="ap-my-3 ap-px-2">
      <div class="ap-typography-body-1 ap-text-center ap-mb-1" i18n>
        Test step
      </div>
      <div class="ap-typography-body-2 ap-text-center ap-mb-3" i18n>
        This step needs to be tested, to generate sample data.
      </div>
      <div class="ap-text-center">
        <ap-button btnColor="primary" btnStyle="flat" type="button" btnSize="default" [darkLoadingSpinner]="false"
          (click)="$event.stopPropagation(); selectStep()" i18n>Go to step
        </ap-button>
      </div>
    </div>
  </ng-template>
</ng-container>
<ng-container *ngIf="search$ | async"></ng-container>
