<div class="ap-typography-headline ap-text-center ap-mb-4">{{ actionTitle }}</div>
<form (ngSubmit)="handlePasswordReset()" class="ap-mb-2">
	<mat-form-field class="ap-w-full" appearance="outline">
		<mat-label>Password</mat-label>
		<input (focus)="menuTrigger.openMenu()" (blur)="menuTrigger.closeMenu()" type="password" matInput
			placeholder="Password" [formControl]="newPasswordControl" (keydown.enter)="handlePasswordReset()" />
		<mat-error *ngIf="newPasswordControl.invalid">
			<ng-container *ngIf="newPasswordControl.hasError('required'); else invalidPasswordMessage">
				Password is required
			</ng-container>
			<ng-template #invalidPasswordMessage>Password is invalid</ng-template>
		</mat-error>
		<div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" class="hidden-trigger"></div>
	</mat-form-field>
</form>


<mat-menu #menu="matMenu" [hasBackdrop]="false">
	<div class="ap-mx-2">
		<div class="ap-flex ap-gap-4 ap-items-center"
			[class.invalid-password-condition]="newPasswordControl.hasError('lowercase')">
			<svg-icon [svgStyle]="{ width: '13px', height: '13px' }"
				src="assets/img/custom/auth/checks/check-invalid.svg" class="grey-check">
			</svg-icon>
			<svg-icon [svgStyle]="{ width: '13px', height: '13px' }"
				src="assets/img/custom/auth/checks/check-correct.svg" class="green-check">
			</svg-icon>
			Lowercase
		</div>
		<div class="ap-flex ap-gap-4 ap-items-center"
			[class.invalid-password-condition]="newPasswordControl.hasError('uppercase')">
			<svg-icon [svgStyle]="{ width: '13px', height: '13px' }"
				src="assets/img/custom/auth/checks/check-invalid.svg" class="grey-check">
			</svg-icon>
			<svg-icon [svgStyle]="{ width: '13px', height: '13px' }"
				src="assets/img/custom/auth/checks/check-correct.svg" class="green-check">
			</svg-icon>
			Uppercase
		</div>
		<div class="ap-flex ap-gap-4 ap-items-center"
			[class.invalid-password-condition]="newPasswordControl.hasError('specialCharacter')">
			<svg-icon [svgStyle]="{ width: '13px', height: '13px' }"
				src="assets/img/custom/auth/checks/check-invalid.svg" class="grey-check">
			</svg-icon>
			<svg-icon [svgStyle]="{ width: '13px', height: '13px' }"
				src="assets/img/custom/auth/checks/check-correct.svg" class="green-check">
			</svg-icon>
			Special Character
		</div>
		<div class="ap-flex ap-gap-4 ap-items-center"
			[class.invalid-password-condition]="newPasswordControl.hasError('number')">
			<svg-icon [svgStyle]="{ width: '13px', height: '13px' }"
				src="assets/img/custom/auth/checks/check-invalid.svg" class="grey-check">
			</svg-icon>
			<svg-icon [svgStyle]="{ width: '13px', height: '13px' }"
				src="assets/img/custom/auth/checks/check-correct.svg" class="green-check">
			</svg-icon>
			Number
		</div>
		<div class="ap-flex ap-gap-4 ap-items-center" [class.invalid-password-condition]="
				newPasswordControl.hasError('maxlength') || newPasswordControl.hasError('minlength') || newPasswordControl.hasError('required')
			">
			<svg-icon [svgStyle]="{ width: '13px', height: '13px' }"
				src="assets/img/custom/auth/checks/check-invalid.svg" class="grey-check">
			</svg-icon>
			<svg-icon [svgStyle]="{ width: '13px', height: '13px' }"
				src="assets/img/custom/auth/checks/check-correct.svg" class="green-check">
			</svg-icon>
			8-64 Characters
		</div>
	</div>

</mat-menu>

<p @fadeInUp class="ap-text-danger ap-text-center ap-mb-2" *ngIf="passwordResetActionError">{{ passwordResetActionError
	}}
</p>

<div class="text-center">
	<ap-button [loading]="resetingPassword" (buttonClicked)="handlePasswordReset()" btnColor="primary"
		[fullWidthOfContainer]="true">
		Confirm
	</ap-button>
</div>
<ng-container *ngIf="resetPassword$ | async"></ng-container>