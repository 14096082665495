<div class="code-editor ap-h-full ap-flex ap-flex-col">
  <div class="modal-header  ap-p-3 ap-typography-headline-6 ap-flex ap-text-white  ap-items-center">
    <span i18n>Code Editor</span>
    <div class="ap-flex-grow"></div>

    <ap-button *ngIf="!readOnly" btnStyle="flat" (buttonClicked)="openNpmPackageModal()" btnSize="small" i18n>
      Add npm package
    </ap-button>
    <ap-button [loading]="testLoading" btnStyle="flat" class="ap-ml-1" btnSize="small"
      (buttonClicked)="openTestCodeModal()" i18n>Test code
    </ap-button>
    <ap-icon-button (click)="hide()" iconFilename="minimize.svg" matTooltip="Close"></ap-icon-button>
  </div>


  <div class="modal-body ap-flex-grow">
    <div class="ap-flex code-height-auto code-container">
      <div class="ap-p-2">
        <div class="ap-p-2">
          <div class="ap-flex ap-flex-col">
            <div class="ap-p-2 ap-items-center ap-flex flex-row file-container"
              [class.file-active]="SelectedFileInFullscreenCodeEditor.CONTENT === selectedFile"
              (click)="selectFile(SelectedFileInFullscreenCodeEditor.CONTENT)">
              <div>
                <svg-icon class="code-file-icon" [svgStyle]="{width:'16px' , height:'16px'}"
                  src="/assets/img/custom/files/js.svg">
                </svg-icon>
              </div>
              <div class="ap-ml-2">index.ts</div>
            </div>

            <div class="ap-p-2 ap-items-center ap-flex flex-row file-container ap-mt-2"
              [class.file-active]="SelectedFileInFullscreenCodeEditor.PACKAGE === selectedFile"
              (click)="selectFile(SelectedFileInFullscreenCodeEditor.PACKAGE)">
              <div>
                <svg-icon class="code-file-icon" [svgStyle]="{width:'16px' , height:'16px'}"
                  src="/assets/img/custom/files/package.svg">
                </svg-icon>
              </div>
              <div class="ap-ml-2">package.json</div>
            </div>
          </div>
        </div>
      </div>
      <div class="ap-p-2 ap-flex-grow overflow-auto">
        <div class="ap-h-full">
          <!-- Use ngModel to update the editor outside the modal -->
          <ngx-monaco-editor class="!ap-h-full" *ngIf="selectedFile === SelectedFileInFullscreenCodeEditor.CONTENT"
            [options]="codeEditorOptions" [ngModel]="codeFilesForm.controls.code.value"
            (ngModelChange)="codeFilesForm.controls.code.setValue($event)"></ngx-monaco-editor>

          <ngx-monaco-editor class="!ap-h-full" *ngIf="selectedFile ===  SelectedFileInFullscreenCodeEditor.PACKAGE"
            [ngModel]="codeFilesForm.controls.packageJson.value"
            (ngModelChange)="codeFilesForm.controls.packageJson.setValue($event)"
            [options]="packageDotJsonOptions"></ngx-monaco-editor>
        </div>

      </div>
      <div class="ap-p-2 console-container">
        <div class="console-size code-height-auto ap-h-full">
          <div class="ap-flex ap-flex-col ap-h-full">
            <div class="ap-flex ap-gap-2">
              <ap-button btnColor="primary"
                [btnStyle]="selectedTab === SelectedTabInFullscreenCodeEditor.OUTPUT? 'flat':'stroked'" class="mr-2"
                btnSize="small" (buttonClicked)="selectTab(SelectedTabInFullscreenCodeEditor.OUTPUT)"> Output
              </ap-button>
              <ap-button btnColor="primary" btnSize="small"
                [btnStyle]="selectedTab === SelectedTabInFullscreenCodeEditor.CONSOLE? 'flat':'stroked'"
                (buttonClicked)="selectTab(SelectedTabInFullscreenCodeEditor.CONSOLE)"> Console
              </ap-button>
            </div>
            <div class="ap-mt-2 ap-flex-grow ap-relative">
              <form [formGroup]="testResultForm" class="ap-h-full">
                <ngx-codemirror *ngIf="selectedTab === SelectedTabInFullscreenCodeEditor.OUTPUT" class="ap-h-full"
                  formControlName="outputResult" [options]="outputResultEditorOptions"></ngx-codemirror>
                <ngx-codemirror *ngIf="selectedTab === SelectedTabInFullscreenCodeEditor.CONSOLE"
                  formControlName="consoleResult" [options]="consoleResultEditoroptions"
                  class="ap-h-full"></ngx-codemirror>
              </form>
              <div class="test-code-loading-container" *ngIf="testLoading">
                <div>
                  <ap-loading-icon [whiteLoader]="true"> </ap-loading-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="addPackageDialogClosed$ | async"></ng-container>
<ng-container *ngIf="executeCodeTest$ | async"></ng-container>
