<div (click)="clickHandler()" (dblclick)="doubleClickHandler()">
  <div
    *ngIf="!this._editing"
    class=" {{ cssClasses }} viewed-text-container"
    [class.!ap-cursor-pointer]="allowDoubleClick"
    #viewedText
    [style.max-width]="viewedTextMaxWidth"
    [matTooltip]="
      viewedText.scrollWidth > viewedText.clientWidth &&
      value &&
      !hideOverflownTextTooltip
        ? value
        : ''
    "
  >
    {{ value }}
  </div>

  <div
    *ngIf="this._editing"
    contentEditable
    class="{{ cssClasses }} text-center"
    [class.hide-overflow]="hideOverflowWhileEditing"
    #editableText
    (focusout)="saveTextOnFocusOut()"
    (keypress)="saveTextOnEnter($event)"
    (keydown.escape)="setEditableOff()"
  >
    {{ value }}
  </div>
</div>
