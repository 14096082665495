<ap-dialog-title-template i18n>Add data source</ap-dialog-title-template>
<mat-dialog-content>
  <form [formGroup]="formGroup" (ngSubmit)="confirmClicked()"
    class="!ap-flex ap-flex-col ap-gap-5 !ap-mt-3 ap-w-[500px]">
    <mat-form-field class="ap-w-full" appearance="outline">
      <mat-label i18n>Name</mat-label>
      <input matInput placeholder="Items Catalog" [formControl]="formGroup.controls.displayName" />
      <mat-error *ngIf="formGroup.controls.displayName.invalid" i18n>
        Name is required
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label i18n>PDF file</mat-label>
      <ap-file-upload [formControl]="formGroup.controls.file" label="File" placeHolder="catalog.pdf" #pdfInput
        [extensions]="['.pdf']"></ap-file-upload>
      <ap-icon-button [height]="25" [width]="25" iconFilename="attach.svg" matSuffix
        (buttonClicked)="pdfInput.fileInput.nativeElement.click()"></ap-icon-button>

      <mat-error *ngIf="formGroup.controls.file.invalid">
        <ng-container *ngIf="formGroup.controls.file.getError('invalidExtenstion')" i18n>Invalid file
          extension</ng-container>
        <ng-container *ngIf="formGroup.controls.file.getError('sizeLimit')" i18n>file size is above limit 4 mega
          bytes</ng-container>
        <ng-container *ngIf="formGroup.controls.file.getError('emptyFile')" i18n>Please choose a file</ng-container>
      </mat-error>
    </mat-form-field>
    <div *ngIf="serverErrorMessage" class="ap-mb-2 ap-typography-caption ap-text-danger">
      {{serverErrorMessage}}
    </div>

    <button class="ap-hidden" type="submit"></button>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <div class="ap-flex ap-gap-2.5">
    <ap-button btnColor="basic" mat-dialog-close btnSize="default" btnColor="basic" i18n>
      Cancel
    </ap-button>
    <ap-button btnSize="default" type="submit" btnColor="primary" (buttonClicked)="confirmClicked()" [loading]="loading"
      i18n>
      Confirm
    </ap-button>
  </div>
</mat-dialog-actions>
<ng-container *ngIf="createBot$ | async"></ng-container>