<ap-dialog-title-template i18n>Add NPM Package</ap-dialog-title-template>
<mat-dialog-content>
  <div class="ap-typography-body-1 ap-text-description ap-mb-4 ap-max-w-[500px]" i18n>
    Please type the name of an NPM package.<br>
  </div>
  <form class="ap-flex ap-flex-col ap-gap-1" [formGroup]="npmForm" (submit)="lookForNpmPackage()">
    <mat-form-field class="ap-w-full" appearance="outline">
      <mat-label>Package Name</mat-label>
      <input cdkFocusInitial formControlName="packageName" matInput type="text" placeholder="loadash" />
      <mat-error *ngIf="npmForm.controls.packageName.invalid"><ng-container
          *ngIf="npmForm.controls.packageName.hasError('required');else invalidName" i18n>
          Package name is required
        </ng-container>
        <ng-template #invalidName>
          <p @fadeInUp class="invalid-input" i18n>
            Package not found
          </p>
        </ng-template>
      </mat-error>
    </mat-form-field>
    <div class="ap-typography-caption ap-text-body" i18n>
      The latest version will be fetched and added to package.json
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div class="ap-flex ap-gap-2.5">
    <ap-button btnColor="basic" mat-dialog-close btnSize="default" btnColor="basic" i18n>
      Cancel
    </ap-button>
    <ap-button btnSize="default" (click)="lookForNpmPackage() " type="submit" btnColor="success" [loading]="loading" i18n>
      Add
    </ap-button>
  </div>
</mat-dialog-actions>

<ng-container *ngIf="npmPackage$ | async"></ng-container>
<ng-container *ngIf="packageNameChanged$  | async"></ng-container>
