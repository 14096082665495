<a href="https://activepieces.com/" target="_blank" rel="noopener noreferrer" class="no-link-styling">
    <div
        class="ap-w-[12.7rem] ap-justify-center ap-cursor-pointer ap-p-2 ap-items-center ap-flex ap-gap-2.5 ap-rounded-md ap-boder-solid ap-border ap-border-outline ap-transition hover:ap-bg-white ap-group">
        <svg-icon src="assets/img/custom/logo/logo.svg"
            class="ap-fill-description group-hover:ap-fill-primary ap-w-[15px] ap-h-[13.64px] ap-transition"
            [applyClass]="true">

        </svg-icon>
        <div
            class="ap-typegraphy-caption ap-text-description !ap-font-semibold group-hover:!ap-text-title ap-transition">
            Built on Activepieces
        </div>
    </div>
</a>