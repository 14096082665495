<div class="ap-w-full ap-h-full ap-px-[30px] ap-pt-[50px]">
  <div class="ap-h-full">
    <ap-page-title title="My Pieces" i18n-title>
      <p underTitle class="ap-text-description ap-typography-body-2 ap ap-mt-2" i18n>
        This is where you can install pieces made by yourself or contributors.
      </p>
    </ap-page-title>
    <div class="ap-min-h-[70%]">
      <table mat-table [dataSource]="dataSource" class="ap-w-[100%]" aria-label="Runs" i18n-aria-label>
        <ng-container matColumnDef="app">
          <th mat-header-cell *matHeaderCellDef i18n>App</th>
          <td mat-cell *matCellDef="let piece"><img appImgFallback="/assets/img/custom/generic_code.svg "
              class="ap-w-[24px] ap-h-[24px] ap-object-contain" [matTooltip]="piece.appName" [src]="piece.logoUrl">
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef i18n>Name</th>
          <td mat-cell *matCellDef="let piece">{{ piece.name }}</td>
        </ng-container>

        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef i18n>Version</th>
          <td mat-cell *matCellDef="let piece">{{ piece.version }}</td>
        </ng-container>


        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="!ap-text-center">
            <ap-button btnColor="primary" btnSize='small' (click)="installPiece()" i18n>
              Add Piece
            </ap-button>
          </th>
          <td mat-cell *matCellDef="let piece" class="!ap-text-center">
            <ap-icon-button tooltipText="Delete Piece" iconFilename="trash.svg" color="warn" [width]="20" [height]="20"
              [height]="16" (buttonClicked)="deleteMetadata(piece)"></ap-icon-button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row [class.ap-hidden]="dataSource.isLoading$ | async" *matRowDef="let row; columns: displayedColumns">
        </tr>
      </table>
      <ng-container *ngIf="(dataSource.isLoading$ | async) === true">
        <div class="ap-flex ap-items-center ap-justify-center ap-flex-grow ap-h-[500px]">
          <ap-loading-icon height="50px" width="51px" [outlineLoader]="true" [whiteLoader]="false"> </ap-loading-icon>
        </div>
      </ng-container>
      <ng-container *ngIf="dataSource.data.length===0 && (dataSource.isLoading$ | async) === false">
        <div class="ap-flex ap-items-center ap-justify-center  ap-h-full ap-h-[500px]" i18n>
          No pieces installed
        </div>
      </ng-container>
    </div>

  </div>
</div>
<ng-container *ngIf="addPackageDialogClosed$ | async"></ng-container>
<ng-container *ngIf="deleteDialogClosed$ | async"></ng-container>