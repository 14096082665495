<ng-container *ngFor="let val of value; let isLast = last; let isFirst = first">
  <div class="ap-relative">
    <div
      class="ap-absolute -ap-left-[55px] ap-z-50 ap-w-[58px] ap-h-[48px]"
      apTrackHover
      #deleteButton="hoverTrackerDirective"
    >
      <ap-icon-button
        [height]="25"
        [width]="25"
        iconFilename="trash.svg"
        color="warn"
        *ngIf="deleteButton.isHovered || panel.isHovered"
        (buttonClicked)="deleteSource(val)"
      ></ap-icon-button>
    </div>
    <mat-expansion-panel
      hideToggle
      disabled
      role="text"
      apTrackHover
      #panel="hoverTrackerDirective"
      [class]="
        '!ap-rounded-none !ap-cursor-default ' +
        (isFirst ? '!ap-rounded-t ' : '') +
        (isLast ? '!ap-rounded-b' : '')
      "
    >
      <mat-expansion-panel-header role="text">
        <mat-panel-description class="!ap-mr-0">
          <div class="ap-flex ap-gap-1 ap-justify-between ap-grow">
            <div
              class="ap-typography-body-1 ap-text-body ap-max-w-[26.25rem] ap-grow ap-truncate"
              *ngIf="val.displayName"
              apCheckOverflow
              (isOverflowed)="
                $event
                  ? (overflowns[val.displayName] = val.displayName)
                  : (overflowns[val.displayName] = '')
              "
              [matTooltip]="overflowns[val.displayName]"
            >
              {{ val.displayName }}
            </div>
            <div class="ap-body-2 ap-font-semibold ap-text-body">PDF</div>
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </div>
</ng-container>
<ng-container *ngIf="value.length === 0">
  <mat-expansion-panel
    role="text"
    hideToggle
    disabled
    class="!ap-cursor-default"
  >
    <mat-expansion-panel-header role="text">
      <mat-panel-title
        class="ap-typography-body-1 ap-font-semibold !ap-text-body" i18n
      >
        No sources added yet
      </mat-panel-title>
    </mat-expansion-panel-header>
  </mat-expansion-panel>
</ng-container>

<div class="ap-mt-3">
  <ap-button
    btnColor="primary"
    btnStyle="basic"
    btnSize="medium"
    [darkLoadingSpinner]="true"
    (buttonClicked)="newSource()"
    [disabled]="saving"
    i18n
  >
    + New Source
  </ap-button>
</div>

<ng-container *ngIf="addNewDataSource$ | async"></ng-container>
