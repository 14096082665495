<ap-dialog-title-template>{{title}}</ap-dialog-title-template>
<mat-dialog-content>
    <div class="code-container  ap-min-w-[500px] ap-min-h-[500px] ap-max-h-[500px] ap-max-w-[750px]">
        <div class="code-area ap-min-w-[500px] ap-min-h-[500px]">
            <pre class="line-numbers ap-max-h-[500px]  ap-min-w-[500px] ap-min-h-[500px] ap-pb-1"
                [class.ap-px-4]="!highlight" [class.ap-py-3]="!highlight"><code class="language-js"
                [innerHTML]="content | outputLog:false"></code></pre>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="ap-flex ap-gap-2.5">
        <ap-button btnColor="basic" mat-dialog-close btnSize="default" i18n>
            Close
        </ap-button>
    </div>
</mat-dialog-actions>
