<mat-toolbar @fadeInUp class="!ap-bg-primary ap-min-h-[64px]" *ngIf="showUpgradeNotification$ | async">
  <div
    class="ap-typography-body-1 ap-flex ap-flex-col ap-items-center ap-flex ap-flex-grow ap-h-full ap-justify-center">
    <div class="ap-typography-body-2 ap-text-white !ap-font-bold ap-flex ap-gap-1 ap-items-center">
      <span>Upgrade Activepieces to latest version to enjoy newest features and pieces!</span>
      <ap-button btnColor="white" btnStyle="basic" class="ap-ml-1" btnSize="small" (buttonClicked)="openUpgradeDocs()"
        i18n>
        Upgrade</ap-button>
      <ap-button btnColor="basic" btnStyle="basic" btnSize="small" (buttonClicked)="ignoreUpgradeNotification()" i18n>
        Ignore</ap-button>
    </div>
  </div>
</mat-toolbar>
<div class="ap-text-body ap-h-full">
  <router-outlet></router-outlet>
</div>

<div *ngIf="(loading$ | async) || (loadingTheme$ | async)"
  class="ap-flex ap-flex-grow ap-justify-center ap-items-center ap-h-full route-loader ap-w-full">
  <svg-icon [svgStyle]="{ width: '50px', height: '50px' }" src="assets/img/custom/loading.svg"
    class="loading-spin-animation grey-spinner" [applyClass]="true"></svg-icon>
</div>
<ng-container *ngIf="loggedInUser$ | async"></ng-container>
<ng-container *ngIf="routeLoader$ | async"></ng-container>
<ng-container *ngIf="openCommandBar$ | async"></ng-container>
<ng-container *ngIf="importTemplate$ | async"></ng-container>
<ng-container *ngIf="theme$ | async"> </ng-container>
<ng-container *ngIf="setTitle$ | async"></ng-container>
<ng-container *ngIf="embeddedRouteListener$ | async"></ng-container>
<ng-container *ngIf="redirect$ | async"> </ng-container>
<ap-iframe-listener></ap-iframe-listener>