<div class="ap-typography-headline-5 !ap-font-semibold ap-m-7.5 ap-flex ap-gap-3 ap-items-center ap-mt-14">
  <ap-icon-button [useSvgDefault]="true" [width]="7" [height]="14" iconFilename="back.svg" tooltipText="Chatbots"
    i18n-tooltipText (buttonClicked)="redirectHome(false)" (auxclick)="redirectHome(true)">
  </ap-icon-button>
  <div i18n>Build Your Chatbot</div>
  <div [class.ap-select-none]="saving" [class.ap-pointer-events-none]="saving">
    <ap-button btnColor="primary" btnSize="medium" btnStyle="stroked" [darkLoadingSpinner]="true"
      [tooltipText]="chatWIthBotTooltipText()" [disabled]="!formGroup.controls.connectionId.value"
      (buttonClicked)="openChatbot()">
      <ng-container *ngIf="saving">
        <div class="ap-flex ap-items-center ap-gap-2 ap-text-body">
          <svg-icon src="assets/img/custom/loading.svg" class="outline-loader loading-spin-animation"
            [svgStyle]="{ width: '15px', height: '15px' }" [applyClass]="true"></svg-icon>

          <ng-container i18n>Saving...</ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="!saving" i18n>Chat with bot</ng-container>
    </ap-button>
  </div>
</div>

<div class="ap-flex ap-divide-x ap-divide-dividers ap-h-[calc(100%-7rem)] ap-flex-wrap">
  <div class="ap-px-15 ap-pt-15 ap-flex ap-flex-col ap-gap-20 ap-divide-y ap-divide-dividers ap-grow ">
    <div class="ap-flex ap-gap-36">
      <div class="ap-text-body-1 ap-font-semibold ap-w-[8.3125rem]" i18n>
        Brand your bot
      </div>
      <form [formGroup]="formGroup" class="ap-w-[22.8125rem]">
        <mat-form-field class="ap-w-full" appearance="outline">
          <mat-label i18n>Name</mat-label>
          <input matInput placeholder="Name" formControlName="displayName" />
          <mat-error *ngIf="formGroup.controls.displayName.invalid" i18n>
            Name is required
          </mat-error>
        </mat-form-field>
        <mat-slide-toggle color="primary" formControlName="isPublic" i18n>
          Public Access
        </mat-slide-toggle>
      </form>
    </div>
    <div class="ap-flex ap-gap-36 ap-pt-20">
      <div class="ap-text-body-1 ap-font-semibold ap-w-[8.3125rem]" i18n>
        Train your bot
      </div>
      <form [formGroup]="formGroup" class="ap-flex ap-flex-col ap-gap-5">
        <div class="ap-relative ap-w-[22.8125rem]">
          <ng-container *ngIf="openAiPiece$ | async as openAiPiece">
            <div class="ap-absolute ap-top-[10px] ap-right-[30px] ap-z-50">
              <app-add-edit-connection-button class="edit-selected-auth" *ngIf="
                  formGroup.controls.connectionId.value && openAiPiece.auth
                " btnSize="extraSmall" [isEditConnectionButton]="true" [authProperty]="openAiPiece.auth"
                [pieceName]="pieceName" [pieceVersion]="pieceVersion" [selectedConnectionInterpolatedString]="
                  (formGroup.controls.connectionId.value
                    | interpolateConnectionNameFromConnectionId : connections$
                    | async) || ''
                " (connectionIdChanged)="connectionValueChanged($event)">
                <div class="ap-px-2" i18n>Reconnect</div>
              </app-add-edit-connection-button>
            </div>
          </ng-container>
          <mat-form-field class="ap-w-full" appearance="outline" #dropdown #dropdownUiContainer="hoverTrackerDirective"
            apTrackHover>
            <mat-label i18n> OpenAI Connection </mat-label>
            <mat-select [formControl]="formGroup.controls.connectionId" [compareWith]="dropdownCompareWithFunction">
              <mat-option (click)="
                  addConnectionBtn.buttonClicked(); $event.preventDefault()
                " [style.display]="
                  (connectionsDropdownList$ | async)?.length === 0
                    ? 'none'
                    : 'flex'
                ">
                <div class="ap-flex">
                  <div class="ap-flex-grow ap-text-primary ap-flex ap-gap-2 ap-font-bold ap-items-center"
                    #noConnectionDiv>
                    <svg-icon src="assets/img/custom/add.svg" [applyClass]="true"
                      class="ap-fill-primary ap-w-[13px] ap-h-[13px]"></svg-icon>
                    <ng-container i18n>New Connection</ng-container>
                  </div>
                </div>
              </mat-option>
              <mat-option *ngFor="let opt of connectionsDropdownList$ | async" [value]="opt.value">
                {{ opt.label.name }}
              </mat-option>

              <mat-option [style.display]="
                  (connectionsDropdownList$ | async)?.length === 0
                    ? 'flex'
                    : 'none'
                " (click)="addConnectionBtn.buttonClicked()" class="add-auth">
                <div class="ap-flex ap-items-center">
                  <div class="ap-flex-grow" i18n>No connections</div>
                  <app-add-edit-connection-button (click)="$event.stopPropagation()" class="edit-selected-auth ap-z-40"
                    btnSize="small" btnSize="extraSmall" [isEditConnectionButton]="false"
                    [authProperty]="(openAiPiece$ | async)?.auth!" [pieceName]="pieceName" [pieceVersion]="pieceVersion"
                    (connectionIdChanged)="connectionValueChanged($event)" i18n>
                    + Add
                  </app-add-edit-connection-button>
                </div>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formGroup.controls.connectionId.invalid" i18n>
              Connection is required
            </mat-error>
          </mat-form-field>
          <app-add-edit-connection-button class="ap-hidden" btnSize="medium" [isEditConnectionButton]="false"
            #addConnectionBtn [isEditConnectionButton]="false" [authProperty]="(openAiPiece$ | async)?.auth!"
            [pieceName]="pieceName" [pieceVersion]="pieceVersion" (connectionIdChanged)="connectionValueChanged($event)"
            i18n>
            + New Connection
          </app-add-edit-connection-button>
        </div>

        <mat-form-field class="ap-w-[22.8125rem]" appearance="outline">
          <mat-label i18n>Bot Attitude</mat-label>
          <textarea autosize matInput [minRows]="1" class="!ap-resize-none !ap-pb-2"
            placeholder="i.e. Act like Ironman's assistant, Jarvis." i18n-placeholder formControlName="prompt">
          </textarea>
          <mat-error *ngIf="formGroup.controls.prompt.invalid" i18n>
            Prompt is required
          </mat-error>
        </mat-form-field>
        <div class="ap-w-[650px]">
          <div class="ap-text-label ap-typography-subtitle-2 ap-mb-3" i18n>
            Training Data Sources
          </div>
          <app-datasources-table [formControl]="formGroup.controls.dataSources" [saving]="saving"
            [chatbotId]="chatbotId" [auth]="formGroup.controls.connectionId.value">
          </app-datasources-table>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-container *ngIf="loadConnections$ | async"></ng-container>
<ng-container *ngIf="saveListener$ | async"></ng-container>
<ng-container *ngIf="saveConnection$ | async"></ng-container>
<ng-container *ngIf="promptChanged$ | async"></ng-container>
<ng-container *ngIf="displayNameChanged$ | async"></ng-container>
<ng-container *ngIf="publicChanged$ | async"></ng-container>