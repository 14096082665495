<div class="ap-inline-block" *ngIf="url">
    <div
        class="ap-rounded-full ap-border ap-border-solid ap-border-dividers ap-h-[35px] ap-w-[35px] ap-flex ap-items-center ap-justify-center">
        <img [src]="url" class="ap-w-[20px] ap-h-[20px] ap-object-contain">
    </div>
</div>

<div class="ap-inline-block ap-relative">
    <div *ngIf="moreIconsNumber > 0"
        class="ap-rounded-full ap-border ap-border-solid ap-border-dividers ap-h-[35px] ap-w-[35px] ap-flex ap-items-center ap-justify-center ap-ml-[5px]">
        <img class="ap-w-[20px] ap-h-[20px] ap-object-contain ap-invisible ">
        <div class="ap-absolute">
            +{{moreIconsNumber}}
        </div>
    </div>
</div>