<div
  class="ap-flex ap-items-center ap-gap-3 ap-absolute ap-top-0 ap-w-full ap-py-1 ap-border-b ap-border-solid ap-border-dividers ap-bg-white ap-h-[56px]"
  [class.ap-px-3]="!isLoggedIn">
  <ap-icon-button [useSvgDefault]="true" [width]="7" [height]="14" iconFilename="back.svg" *ngIf="isLoggedIn"
    [tooltipText]="'Home'" (buttonClicked)="redirectHome(false)" (auxclick)="redirectHome(true)"></ap-icon-button>
  <div class="ap-absolute ap-w-full ap-flex ap-items-center ap-justify-center ap-top-0 ap-p-4 ap-pointer-events-none">
    <div class="ap-h-[30px]">
      <img [src]=" (fullLogo$| async) ||'assets/img/custom/logo/full-logo.svg'" class="ap-max-w-full ap-h-full">
    </div>
  </div>
  <div class="ap-typography-body-1 !ap-font-semibold">
    {{ chatbotDisplayName }}
  </div>
</div>

<div class="ap-container ap-mx-auto ap-h-[calc(100%-70px)]">
  <div #chatThread id="chatThread"
    class="ap-flex ap-flex-col ap-gap-4 ap-items-center ap-justify-center thin-scrollbars ap-overflow-auto ap-mb-2 ap-mt-[60px] ap-scroll-smooth"
    [class.!ap-justify-start]="messages.length > 0 || (sendingMessage$ | async)" [style.height]="'calc( 100% - ' +
    textareaContainer.clientHeight + 'px )'">
    <ng-container *ngIf=" messages.length===0 && (sendingMessage$ | async)===false; else messageThread ">
      <svg-icon src=" assets/img/custom/dashboard/chatbots.svg" [applyClass]="true"
        class="ap-fill-body ap-h-[60px] ap-w-[60px]"></svg-icon>
      <div class="ap-typography-headline-6 ap-text-body" i18n>Ask me something</div>
    </ng-container>
  </div>
  <div #textareaContainer>
    <mat-form-field class="ap-w-full" appearance="outline">
      <textarea autosize matInput [minRows]="1" [maxRows]="8" class="!ap-resize-none thin-scrollbars ap-mb-3"
        (keyDown.enter)="$event.preventDefault(); send()" [formControl]="messageControl" placeholder="Send a message" i18n-placeholder>
      </textarea>
      <div matSuffix class="ap-mx-2">
        <ap-icon-button [useSvgDefault]="true" [iconFilename]="'send.svg'"
          [buttonDisabled]="(sendingMessage$ | async) || !messageControl.value"
          (buttonClicked)="send()"></ap-icon-button>
      </div>
    </mat-form-field>
  </div>
</div>

<ng-container *ngIf="sendMessage$ | async"></ng-container>

<ng-template #messageThread>
  <div class="ap-w-full">
    <ng-container *ngFor="let msg of messages">
      <div class="ap-p-7 ap-typography-body-1 ap-flex ap-items-center ap-gap-3"
        [class.ap-bg-header]="msg.role === 'bot'">
        <svg-icon *ngIf="msg.role === 'bot'" class="ap-w-[24px] ap-h-[24px] ap-fill-body"
          src="assets/img/custom/dashboard/chatbots.svg" [applyClass]="true"></svg-icon>
        <svg-icon *ngIf="msg.role === 'user'" class="ap-w-[24px] ap-h-[24px]" src="assets/img/custom/person.svg"
          [applyClass]="true"></svg-icon>
        <markdown clipboard lineNumbers [data]="msg.text" ngPreserveWhitespaces clip
          [clipboardButtonTemplate]="buttonTemplate">


        </markdown>

      </div>
    </ng-container>
    <div *ngIf="sendingMessage$ | async"
      class="ap-p-7 ap-typography-body-1 ap-flex ap-items-center ap-gap-3 ap-bg-header">
      <svg-icon class="ap-w-[24px] ap-h-[24px] ap-fill-body" src="assets/img/custom/dashboard/chatbots.svg"
        [applyClass]="true"></svg-icon>
        {{ chatbotDisplayName }} <ng-container i18n>is thinking</ng-container> {{ dots$ | async }}
    </div>
  </div>
</ng-template>

<ng-container *ngIf="data$ | async"></ng-container>

<ng-template #buttonTemplate>
  <ap-icon-button [width]="15" [height]="15" [iconFilename]="'copy.svg'" tooltipText="Copy Code" i18n-tooltipText
    (buttonClicked)="codeCopied()"></ap-icon-button>
</ng-template>