<div class="btn-container ap-relative" [matTooltip]="tooltipText" *ngIf="!onlyShowContent">
  <ng-container [ngSwitch]="btnStyle">
    <button [type]="type" [class]="btnSizeClass" [class.ap-w-full]="fullWidthOfContainer" *ngSwitchCase="'flat'"
      mat-flat-button [color]="btnColor" [disabled]="disabled ? true : false" (click)="click()"
      [tabIndex]="buttonIsInsideAutocomplete? -1 : 0">
      <ng-container *ngTemplateOutlet="btnContent"></ng-container>
    </button>
    <button [class]="btnSizeClass" [type]="type" [class.ap-w-full]="fullWidthOfContainer" *ngSwitchCase="'basic'"
      [class.inside-hover-item]="buttonIsInsideAutocomplete" mat-button [color]="btnColor"
      [disabled]="disabled ? true : false" (click)="click()" [tabIndex]="buttonIsInsideAutocomplete? -1 : 0">
      <ng-container *ngTemplateOutlet="btnContent"></ng-container>
    </button>
    <button [type]="type" [class]="btnSizeClass" [class.ap-w-full]="fullWidthOfContainer" *ngSwitchCase="'raised'"
      mat-raised-button [color]="btnColor" [disabled]="disabled ? true : false" (click)="click()"
      [tabIndex]="buttonIsInsideAutocomplete? -1 : 0">
      <ng-container *ngTemplateOutlet="btnContent"></ng-container>
    </button>
    <button [type]="type" [class]="btnSizeClass" [class.ap-w-full]="fullWidthOfContainer" *ngSwitchCase="'stroked'"
      mat-stroked-button [color]="btnColor" [disabled]="disabled ? true : false" (click)="click()"
      [tabIndex]="buttonIsInsideAutocomplete? -1 : 0">
      <ng-container *ngTemplateOutlet="btnContent"></ng-container>
    </button>
  </ng-container>
  <div class="ap-absolute ap-h-full ap-w-full ap-left-0 ap-top-0 ap-z-50" *ngIf="disabled" [matTooltip]="tooltipText">
  </div>
</div>
<div [class]="btnSizeClass" *ngIf="onlyShowContent">
  <ng-container *ngTemplateOutlet="btnContent"></ng-container>
</div>
<ng-template #btnContent>
  <div [class.ap-opacity-0]="!loading" class="spinner-border loading-spinner-size-and-position"
    [class.dark-spinner]="darkLoadingSpinner" role="status"></div>
  <div [class.ap-opacity-0]="loading">
    <ng-content></ng-content>
  </div>
</ng-template>